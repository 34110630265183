export const returnResources = (page) => {
    const results = [];
      if (page.responseType) {
        if (page.resourceType) {
          page.resourceType.flatMap((resourceItem) => {

            return results.push(`${resourceItem.id}`);
            })
        }
        page.responseType.flatMap((responseItem) => {
          return results.push(responseItem.guid);

        })
      } else {
        results.push(`${page.resourceType[0].id}`);
      }
      return results.filter(individualItem => individualItem !== undefined);
    
};

export default returnResources;