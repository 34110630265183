import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Brand from '../Brand';
import Header from './header';
import { Col, Container, Row } from 'react-bootstrap';
import { Sidebar } from '@capp.micro/shared/components';
import { useAuth0 } from '@auth0/auth0-react';
import { Helmet } from 'react-helmet';
import { completeStep, getApiUserMetadata } from '@capp.micro/shared/api';

const Layout = ({ children }) => {
  const isBrowser = typeof window !== 'undefined';
  const [userMetadata, setUserMetadata] = useState(null);
  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();
  
  useEffect(() => {
    const updateUserMetadata = async () => {
      completeStep(Brand, user, getAccessTokenSilently, setUserMetadata, false);
    };

    getApiUserMetadata(user, getAccessTokenSilently, setUserMetadata);
    updateUserMetadata();
  }, [getAccessTokenSilently, user]);

  const profile =
    isAuthenticated && userMetadata
      ? userMetadata[`${Brand.BRAND_NAME}profileComplete`]
      : '';

  return (
    <>
      
      <Helmet
        htmlAttributes={{
          lang: 'en',
          'xmlns:og': 'http://ogp.me/ns#',
          'xmlns:fb': 'http://ogp.me/ns/fb#',
        }}
      >
        <meta charSet="utf-8" />
        <meta content="width=device-width, initial-scale=1.0" name="viewport" />
        ....
      </Helmet>

      <Header />
      {isBrowser && window.location.pathname === '/' && (
        
          <main>{children}</main>
         
        
      )}
      {isBrowser && window.location.pathname === '/testing-sign-up' && (
    
          <main>{children}</main>
         
  
      )}

      {isBrowser && window.location.pathname !== '/' && (
        <div
          style={{
            margin: `0 auto`,
            height: '100vh',
            width: '100%',
            padding: '0',
          }}
        >
          <main>
            <Container style={{ maxWidth: '100%', padding: '0' }}>
              <Row style={{ maxWidth: '100%', padding: '0' }}>
                <Col
                  className="sidebar-wrapper"
                  xs={12}
                  lg={2}
                  style={{ maxWidth: '100%', padding: '0' }}
                >
                  <Sidebar
                    buttonLink="https://www.figma.com/proto/yB25x0kBrwHISm3kixT9on/Find-Your-Fit?page-id=41%3A1368&node-id=62-767&viewport=1318%2C1023%2C0.18&scaling=scale-down-width&starting-point-node-id=62%3A767"
                    buttonLink2="https://deloittecandidate.ambertrack.co.uk/studentprogrammes2023/CandidateLogin.aspx?cookieCheck=true"
                    buttonText="Click here to Find Your Fit"
                    buttonText2="Apply Now"
                    item1="Practice zone"
                    link1="/dashboard/"
                    item2="Learn about you"
                    link2="/learn-about-you/"
                    item3="Practice questions"
                    link3="/practice-questions/"
                    item4="Immersive assessment"
                    link4="/immersive-assessment/"
                    item5="FAQs"
                    link5="/faqs/"
                    
                   
                    extraLinks={[
                   
               
          
                    ]}
                    navClass1={
                      isAuthenticated && userMetadata && !profile
                        ? 'link-not-disabled'
                        : ''
                    }
                    navClass2={
                      isAuthenticated && userMetadata && !profile
                        ? 'link-not-disabled'
                        : ''
                    }
                    navClass3={
                      isAuthenticated && userMetadata && !profile
                        ? 'link-not-disabled'
                        : ''
                    }
                    image={Brand.POWERED_BY_CAPP_LOGO}

                
                  />
                </Col>
                <Col
                  className="main"
                  xs={12}
                  lg={10}
                  style={{
                    padding: '0',
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'hidden'
                  }}
                >
                  {children}
                </Col>
              </Row>
            </Container>
          </main>
        </div>
      )}
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
