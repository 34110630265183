import React, { useEffect, useState } from 'react';
import Hero from '../components/hero';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { SidebarHeader, SidebarCard } from '@capp.micro/shared/components';
import {
  FloatingPanel,
  JumboSectionCTA,
  JumboTwoColsLeft,
  JumboTwoColsRight,
} from '@capp.micro/shared/components';
import Layout from '../components/layout';
import Brand from '../Brand';
import tick from '../images/brand-tick.svg';
import cognitive from '../images/cognitive.svg';
import practice from '../images/practice.svg';
import sbi from '../images/sbi.svg';
import brain from '../images/brain.svg';
import showcase from '../images/showcase.svg';
import { Container, Row, Col } from 'react-bootstrap';
import { getApiUserMetadata } from '@capp.micro/shared/api';

export function Dashboard() {
  const [userMetadata, setUserMetadata] = useState(null);
  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    getApiUserMetadata(user, getAccessTokenSilently, setUserMetadata);
  }, []);

  const userID = isAuthenticated ? user.sub : 'Unknown';
  const email = isAuthenticated ? encodeURIComponent(user.email) : '';
  const fname = isAuthenticated && userMetadata ? userMetadata.first_name : '';
  const lname = isAuthenticated && userMetadata ? userMetadata.last_name : '';
  const profile =
    isAuthenticated && userMetadata ? userMetadata.yourcoprofileComplete : '';
  const step2 =
    isAuthenticated && userMetadata ? userMetadata.yourcostep2Complete : '';
  const step3 =
    isAuthenticated && userMetadata ? userMetadata.yourcostep3Complete : '';
  const step4 =
    isAuthenticated && userMetadata ? userMetadata.yourcostep4Complete : '';
  const step5 =
    isAuthenticated && userMetadata ? userMetadata.yourcostep5Complete : '';
  console.log(profile);

  return (
    <Layout>
      <SidebarHeader
        class="sidebar-header page-one capp-gradient"
        heading="This is a test"
        subheading="This is some more text"
      />
      <Container>
        <Row>
          <Col xs={12} lg={4}>
            <SidebarCard heading="Testing 123" text="This is some text" />
          </Col>
          <Col xs={12} lg={4}>
            <SidebarCard heading="Testing 123" text="This is some text" />
          </Col>
          <Col xs={12} lg={4}>
            <SidebarCard heading="Testing 123" text="This is some text" />
          </Col>
        </Row>
      </Container>
      <Hero
        welcomeTitle="Welcome to"
        title="Preparation Plus"
        subtitle="The virtual experience and engagement platform"
        text=""
        image={cognitive}
        class="jumbo-section hero full-width hero-light capp-gradient"
      />
      <a name="profile" />
      <FloatingPanel
        numberCheck={
          isAuthenticated && user && profile ? 'checked' : 'number-check'
        }
        number={isAuthenticated && user && profile ? <img src={tick} /> : '1'}
        title="Your profile"
        text="Please fill out your profile so we can find out more about you."
        buttonText={
          isAuthenticated && user && profile ? 'Completed' : 'Start now'
        }
        link={
          isAuthenticated && user && profile
            ? '#'
            : Brand.ASSESSMENT_LINKS.PROFILE_LINK +
              '&cuid=' +
              userID +
              '&fname=' +
              fname +
              '&lname=' +
              lname +
              '&email=' +
              email +
              '&auid=104d7123-6216-4c60-8449-28bc31ff77ce'
        }
        class="pt-3 pb-3  brand-profile jumbo-section brand-profile floating-profile"
      />
      <a name="worksim" />
      <JumboTwoColsRight
        numberCheck={
          isAuthenticated && user && step2 ? 'checked' : 'number-check'
        }
        number={isAuthenticated && user && step2 ? <img src={tick} /> : '2'}
        title="Practice work sim"
        text="Success starts with true self-awareness.Explore your strengths, values, and preferences. Find your best career paths."
        image={practice}
        buttonText={
          isAuthenticated && user && profile
            ? 'Find out more'
            : 'Complete Profile First'
        }
        link={isAuthenticated && user && profile ? '/worksim' : '#profile'}
        class="mt-10 mb-3 brand-discover jumbo-section full-width work-sim"
      />

      <a name="cognitive" />
      <JumboTwoColsLeft
        numberCheck={
          isAuthenticated && user && step3 ? 'checked' : 'number-check'
        }
        number={isAuthenticated && user && step3 ? <img src={tick} /> : '3'}
        title="Cognitive practice test"
        text="Please fill out your profile so we can find out more about you. Something like this can go here"
        buttonText={
          isAuthenticated && user && step2
            ? 'Find out more'
            : 'Complete previous step'
        }
        link={isAuthenticated && user && step2 ? '/cognitive' : '#worksim'}
        image={brain}
        class="mb-3 pt-7 pb-3 mt-3 brand-profile full-width jumbo-section brand-profile swap white-text coloured cog"
      />

      <a name="sbi" />
      <JumboTwoColsRight
        numberCheck={
          isAuthenticated && user && step4 ? 'checked' : 'number-check'
        }
        number={isAuthenticated && user && step4 ? <img src={tick} /> : '4'}
        title="Strengths based interview"
        text="Success starts with true self-awareness.Explore your strengths, values, and preferences. Find your best career paths."
        image={sbi}
        buttonText={
          isAuthenticated && user && step3
            ? 'Find out more'
            : 'Complete previous step'
        }
        link={
          isAuthenticated && user && step3 ? '/strengthsbased' : '#cognitive'
        }
        class="mb-3 pt-5 brand-discover jumbo-section full-width sbi"
      />

      <a name="showcase" />
      <JumboSectionCTA
        numberCheck={
          isAuthenticated && user && step5 ? 'checked' : 'number-check'
        }
        number={isAuthenticated && user && step5 ? <img src={tick} /> : '5'}
        title="Showcase"
        text="Show employers what you can do. Be evaluated on your performance. Virtual work simulations for real job opportunities with leading employers."
        image={showcase}
        buttonText={
          isAuthenticated && user && step4
            ? 'Find out more'
            : 'Complete previous step'
        }
        link={isAuthenticated && user && step4 ? '/showcase' : '#sbi'}
        class="jumbo-section full-width center-title mobile-mt-0 mobile-mb-0 mobile-pb-3 pt-7 large-title brand-showcase white-text coloured"
      />
    </Layout>
  );
}

export default withAuthenticationRequired(Dashboard, {
  loginOptions: {
    dbConnection: Brand.AUTH_CONNECTION,
    backgroundImage: Brand.SIGN_UP_BG,
    logo: Brand.SIGN_UP_LOGO,
    buttonColor: Brand.SIGN_UP_BUTTON_COLOR,
    buttonHoverColor: Brand.SIGN_UP_BUTTON_HOVER,
    buttonTextColor: Brand.BUTTON_TEXT_COLOR,
    headerColor: Brand.SIGN_UP_HEADER_COLOR,
    welcomeOneColor: Brand.WELCOME_TEXT_COLOR_ONE,
    welcomeTwoColor: Brand.WELCOME_TEXT_COLOR_TWO,
    welcomeTwo: Brand.WELCOME_TEXT,
    appName: Brand.LOG_IN_TITLE,
  },
});
