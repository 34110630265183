import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Container, Row, Col } from 'react-bootstrap';
import {
  SliderQuestion,
  MultipleChoice,
  FlipCard,
  FreeText,
  FreeTextQuiz,
  Quiz,
  RankingList,
  Checklist,
  ImageContent,
  VideoContent,
  TextContent,
  VideoCapture,
  BucketSort
} from '@capp.micro/shared/components';
import PropTypes from 'prop-types';

const ResponseRenderer = ({ name, brand, user, getAccessTokenSilently, phaseNumber, moduleNumber, pageNumber,setUserMetadata, ...props }) => {
ResponseRenderer.propTypes = {
  name: PropTypes.string,
  brand: PropTypes.object, 
  user: PropTypes.object, 
  getAccessTokenSilently: PropTypes.func, 
  phaseNumber: PropTypes.string, 
  moduleNumber: PropTypes.string, 
  pageNumber: PropTypes.string,
  setUserMetadata: PropTypes.func
}
    const RESPONSE_COMPONENT_MAP = {
      freeText: FreeText,
      listSort: RankingList,
      quiz: Quiz,
      quizA: Quiz,
      quizB: Quiz,
      freeTextQuiz: FreeTextQuiz,
      flipCard: FlipCard,
      checklist: Checklist,
      multipleChoice: MultipleChoice,
      sliderQuestion: SliderQuestion,
      bucketSort: BucketSort,
      videoCapture: VideoCapture,
    };

    const ComponentToRender = RESPONSE_COMPONENT_MAP[name];
    
    if (!ComponentToRender) {
      return null;
    }
    
    return <ComponentToRender {...props} brand={brand} user={user} getAccessTokenSilently={getAccessTokenSilently}  phaseNumber={phaseNumber}
    moduleNumber={moduleNumber}
    pageNumber={pageNumber}
    setUserMetadata={setUserMetadata} />;
  }
  
  const ContentRenderer = ({ title, content, afterResponse, summary, action, time, resourceType, responseType, moreResources,setUserMetadata, brand, user, getAccessTokenSilently, phaseNumber, moduleNumber, pageNumber, ...props }) => {
    
    ContentRenderer.propTypes = {
      title: PropTypes.string,
      content: PropTypes.array,
      afterResponse: PropTypes.array,
      summary: PropTypes.object,
      action: PropTypes.array,
      time: PropTypes.string,
      resourceType: PropTypes.array,
      responseType: PropTypes.array,
      moreResources: PropTypes.array,
      setUserMetadata: PropTypes.func, 
      brand: PropTypes.object, 
      user: PropTypes.object, 
      getAccessTokenSilently: PropTypes.func, 
      phaseNumber: PropTypes.string, 
      moduleNumber: PropTypes.string, 
      pageNumber: PropTypes.string, 
    }

    const TYPE_COMPONENT_MAP = {
      video: VideoContent,
      image: ImageContent,
      text: TextContent,
    };

    return (
      <>
        <h1>{title}</h1>
        <div className='resource-container'>
          {resourceType?.map((resource, _index) => {
            const ComponentToRender = TYPE_COMPONENT_MAP[resource.name];
            if (!ComponentToRender) {
                return null;
            }
            return (
                <ComponentToRender 
                    key={resource.id}
                    {...resource} 
                    {...props} 
                    brand={brand} user={user} getAccessTokenSilently={getAccessTokenSilently} phaseNumber={phaseNumber}
                    moduleNumber={moduleNumber}
                    pageNumber={pageNumber}
                    setUserMetadata={setUserMetadata}
                    id={resource.id}
                   
                />
            );
          })}
      </div>

      {content?.map((text) => (
        <div key={text.text} className='content'>
            <p className={text.class} dangerouslySetInnerHTML={{ __html: text.text }}></p>
            {text.image && <img src={text.image.src} alt={text.image.alt} />}
            {text.list && (
              <ul className='content-list'>
                {text.list.map(item => <li key={item}>{item}</li>)}
              </ul>
            )}
        </div>
      ))}


      {time && <div key={time} className='page-time'>{time}</div>}




      <div className='response-container'>
        {responseType?.map((response) => {
          if (response.type === 'content') {
            return <div key={response.id} className='in-between-content'>{response.text}</div>;
          } else {
            return (
              <div key={response.id} className={response.class}>
                {response.questionHeader && <h2 className='question-header'>{response.questionHeader}</h2>}
                {response.questionText && <h2 className='question-text'>{response.questionText}</h2>}
                <ResponseRenderer 
                    {...response} 
                    {...props} 
                    brand={brand} 
                    user={user} 
                    getAccessTokenSilently={getAccessTokenSilently}  
                    phaseNumber={phaseNumber}
                    moduleNumber={moduleNumber}
                    pageNumber={pageNumber}
                    setUserMetadata={setUserMetadata}
                />
              </div>
            );
          }
        })}
      </div>

      
     

      <div className='after-response'><p className='content'>{afterResponse}</p></div>


      {summary?.map((text) => (
        <div key={text.text} className='content'>
            <p className={text.class}>{text.text}</p>
            {text.image && <img src={text.image.src} alt={text.image.alt} />}
            {text.list && (
              <ul className='content-list'>
                {text.list.map(item => <li key={item}>{item}</li>)}
              </ul>
            )}
        </div>
      ))}

      {moreResources?.map((resource) => (
        <Container  key={resource.id} className='more-resources-container'>
          <Row>
            <Col xs={12}> <h2>{resource.name}</h2></Col>
          </Row>
          <Row>
         {resource.items?.map((item) => (
          <Col xs={12} lg={4} key={item.id}>
            <div key={item.id} className={`${item.class} recommended-tile`}>
              <div className='recommended-text'>
                {/* <h2>{item.heading}</h2> */}
                <p>{item.name}</p>
              </div>
              <div className='recommended-button'>
              <a
                className="btn btn-primary"
                href={item.link}
                target="_blank"
                rel="noreferrer"
              >
                {item.linkText}
              </a>
              </div>
            </div>
            </Col>
         ))}
         </Row>
         </Container>
      ))}
      {action?.map((text) => (

            <div key={text} className='action'><p className='content info'>{text}</p></div>

      ))}
      </>
    );
}


export const PageComponent = ({brand, selectedPage, setUserMetadata, phaseNumber, moduleNumber}) => {
  PageComponent.propTypes = {
    brand: PropTypes.object.isRequired,
    selectedPage: PropTypes.object.isRequired,
    setUserMetadata: PropTypes.func.isRequired,
    phaseNumber: PropTypes.string.isRequired,
    moduleNumber: PropTypes.string.isRequired
  }
  
  const { user, getAccessTokenSilently } = useAuth0();
  if (!selectedPage) {return <p>No item selected</p>};
    
    return (
        <Container className='page-container'>
          <Row>
            <Col xs={12}>
              <div className="dashboard-intro" style={{height: "auto"}}>    
                <div> 
                   <ContentRenderer 
                      {...selectedPage}
                      brand={brand}
                      getAccessTokenSilently={getAccessTokenSilently} 
                      user={user}
                      setUserMetadata={setUserMetadata}
                      phaseNumber={phaseNumber}
                      moduleNumber={moduleNumber}
                      pageNumber={selectedPage.number}
                    /> 
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      );
};