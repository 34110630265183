import React, { useEffect, useState } from 'react';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { SidebarHeader } from '@capp.micro/shared/components';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import Layout from '../components/layout';
import Brand from '../Brand';
import { Container, Row, Col } from 'react-bootstrap';
import { getApiUserMetadata } from '@capp.micro/shared/api';

export function PracticeAssessment() {
  const [userMetadata, setUserMetadata] = useState(null);
  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    getApiUserMetadata(user, getAccessTokenSilently, setUserMetadata);
  }, []);

  const userID = isAuthenticated ? user.sub : 'Unknown';
  const email = isAuthenticated ? encodeURIComponent(user.email) : '';
  const fname = isAuthenticated && userMetadata ? userMetadata.first_name : '';
  const lname = isAuthenticated && userMetadata ? userMetadata.last_name : '';

  const step7 =
    isAuthenticated && userMetadata
      ? userMetadata[`${Brand.FLAG_NAME}step7Complete`]
      : '';

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{Brand.PAGE4_TITLE}</title>
      </Helmet>
      <SidebarHeader
        class="sidebar-header page-four capp-gradient"
        heading="Practice Immersive Assessment"
        subheading="STUDENTS & GRADUATES"
      />

      <Container className="cards-wrapper">
        <Row>
          <Col xs={12} lg={12}>
            <div className="practice-question-card">
              <p>
                By working through this experience, you will be presented with
                scenarios and settings that are commonplace in today's world of
                work at Cappfinity - allowing you to truly immerse yourself into
                the types of environments you could well find yourself enjoying
                in the future if you are to make a successful application with
                us.
              </p>

              <p>
                Through your responses to the questions and challenges posed,
                you will ultimately be building an indicative profile of how you
                think, how you feel and how you act in certain settings and this
                is an excellent basis upon which we can begin to understand more
                about your suitability to our roles.
              </p>

              <p>
                Go at your own pace as nothing here is timed, try to complete
                the mock assessment in one go and be as honest as you can to
                ensure you give a true and accurate representation of yourself.
              </p>
              <motion.button
                className="btn btn-primary"
                variant="primary"
                onClick={(e) =>
                  {window.location.href =
                    Brand.ASSESSMENT_LINKS.SHOWCASE_LINK +
                    '&cuid=' +
                    userID +
                    '&fname=' +
                    fname +
                    '&lname=' +
                    lname +
                    '&email=' +
                    email +
                    '&auid=' +
                    Brand.AUID;
                    e.target.disabled = "true";}
                }
                disabled={isAuthenticated && user && step7 ? 'true' : ''}
              >
                {isAuthenticated && user && step7 ? 'Completed' : 'Start Now'}
              </motion.button>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}

export default withAuthenticationRequired(PracticeAssessment, {
  loginOptions: {
    dbConnection: Brand.AUTH_CONNECTION,
    backgroundImage: Brand.SIGN_UP_BG,
    logo: Brand.SIGN_UP_LOGO,
    buttonColor: Brand.SIGN_UP_BUTTON_COLOR,
    buttonHoverColor: Brand.SIGN_UP_BUTTON_HOVER,
    buttonTextColor: Brand.BUTTON_TEXT_COLOR,
    headerColor: Brand.SIGN_UP_HEADER_COLOR,
    welcomeOneColor: Brand.WELCOME_TEXT_COLOR_ONE,
    welcomeTwoColor: Brand.WELCOME_TEXT_COLOR_TWO,
    welcomeTwo: Brand.WELCOME_TEXT,
    appName: Brand.LOG_IN_TITLE,
  },
});
