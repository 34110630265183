import React, { useEffect, useState } from 'react';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { SidebarHeader, SidebarCard } from '@capp.micro/shared/components';
import { Helmet } from 'react-helmet';
import Layout from '../components/layout';
import Brand from '../Brand';
import { Container, Row, Col } from 'react-bootstrap';
import { getApiUserMetadata } from '@capp.micro/shared/api';

export function LearnAboutYou() {
  const [userMetadata, setUserMetadata] = useState(null);
  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    getApiUserMetadata(user, getAccessTokenSilently, setUserMetadata);
  }, []);

  const userID = isAuthenticated ? user.sub : 'Unknown';
  const email = isAuthenticated ? encodeURIComponent(user.email) : '';
  const fname = isAuthenticated && userMetadata ? userMetadata.first_name : '';
  const lname = isAuthenticated && userMetadata ? userMetadata.last_name : '';

  const step1 =
    isAuthenticated && userMetadata
      ? userMetadata[`${Brand.FLAG_NAME}step1Complete`]
      : '';
  const step2 =
    isAuthenticated && userMetadata
      ? userMetadata[`${Brand.FLAG_NAME}step2Complete`]
      : '';
  const step3 =
    isAuthenticated && userMetadata
      ? userMetadata[`${Brand.FLAG_NAME}step3Complete`]
      : '';

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{Brand.PAGE2_TITLE}</title>
      </Helmet>
      <SidebarHeader
        class="sidebar-header page-two capp-gradient"
        heading="Learn about you"
        subheading="STUDENTS & GRADUATES"
      />

      <Container className="cards-wrapper">
        <Row>
          <Col xs={12} lg={4}>
            <SidebarCard
              alt={Brand.BRAND_NAME}
              class="sidebar-card card-one"
              heading={['Values', <br />, <br />]}
              text={[
                'Begin to understand what your values are and how they may align to ours.',
              ]}
              disabled={isAuthenticated && user && step1 ? 'true' : ''}
              buttonText={
                isAuthenticated && user && step1 ? 'Completed' : 'Start Now'
              }
              link={
                Brand.ASSESSMENT_LINKS.VALUES_LINK +
                '&cuid=' +
                userID +
                '&fname=' +
                fname +
                '&lname=' +
                lname +
                '&email=' +
                email +
                '&auid=' +
                Brand.AUID
              }
            />
          </Col>
          <Col xs={12} lg={4}>
            <SidebarCard
              alt={Brand.BRAND_NAME}
              class="sidebar-card card-two"
              heading={['Preferences', <br />, <br />]}
              text={[
                'Answer these short questions to help understand what type of work you might be best suited to. ',
              ]}
              buttonText={
                isAuthenticated && user && step2 ? 'Retake' : 'Start Now'
              }
              link={Brand.ASSESSMENT_LINKS.PREF_LINK}
            />
          </Col>

          <Col xs={12} lg={4}>
            <SidebarCard
              alt={Brand.BRAND_NAME}
              class="sidebar-card card-three"
              heading={['Learning Styles', <br />, <br />]}
              text={[
                'A short experience to help you build a greater appreciation of how best you might learn new things.',
              ]}
              disabled={isAuthenticated && user && step3 ? 'true' : ''}
              buttonText={
                isAuthenticated && user && step3 ? 'Completed' : 'Start Now'
              }
              link={
                Brand.ASSESSMENT_LINKS.LEARNING_LINK +
                '&cuid=' +
                userID +
                '&fname=' +
                fname +
                '&lname=' +
                lname +
                '&email=' +
                email +
                '&auid=' +
                Brand.AUID
              }
            />
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}

export default withAuthenticationRequired(LearnAboutYou, {
  loginOptions: {
    dbConnection: Brand.AUTH_CONNECTION,
    backgroundImage: Brand.SIGN_UP_BG,
    logo: Brand.SIGN_UP_LOGO,
    buttonColor: Brand.SIGN_UP_BUTTON_COLOR,
    buttonHoverColor: Brand.SIGN_UP_BUTTON_HOVER,
    buttonTextColor: Brand.BUTTON_TEXT_COLOR,
    headerColor: Brand.SIGN_UP_HEADER_COLOR,
    welcomeOneColor: Brand.WELCOME_TEXT_COLOR_ONE,
    welcomeTwoColor: Brand.WELCOME_TEXT_COLOR_TWO,
    welcomeTwo: Brand.WELCOME_TEXT,
    appName: Brand.LOG_IN_TITLE,
  },
});
