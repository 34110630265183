import React, { useEffect, useState } from 'react';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { SidebarHeader } from '@capp.micro/shared/components';
import { Helmet } from 'react-helmet';
import Layout from '../components/layout';
import Brand from '../Brand';
import { Container, Row, Col } from 'react-bootstrap';
import { getApiUserMetadata } from '@capp.micro/shared/api';

export function PracticeQuestions() {
  const [, setUserMetadata] = useState(null);
  const { user, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    getApiUserMetadata(user, getAccessTokenSilently, setUserMetadata);
  }, []);

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{Brand.PAGE5_TITLE}</title>
      </Helmet>
      <SidebarHeader
        class="sidebar-header page-four capp-gradient"
        heading="How to have great career conversations"
        subheading="Prepare"
      />
      <Container className="cards-wrapper">
        <Row>
          <Col xs={12} lg={10}>
            <p className="intro">
              Before we jump in, let's set the scene. Career conversations are
              distinct from performance conversations but are just as, if not
              more, important. Career conversations are about growth,
              development and discussing your future aspirations. Having a great
              career conversation can leave you feeling motivated in your
              current role and empowered to reach for your future goals.
            </p>

            <p>
              As part of our new approach to identifying and developing
              potential, we’re encouraging colleagues and line managers to have
              regular conversations about strengths, opportunities for growth
              and career progression. During our new BA Talent Cycle, you’ll
              have at least two conversations with your line manager:
            </p>

            <br />
            <h3>
              1. Discuss ‘Your Potential’ report (colleague and line manager)
            </h3>

            <br />
            <p>
              This is a chance for you to discuss your strengths and development
              areas with your line manager, as well as dive deeper into the
              answers you gave to the motivational questions in the online
              survey.
            </p>

            <br />
            <h3>
              2. ‘Growing your career’ conversation (colleague and line manager)
            </h3>

            <br />
            <p>
              This is a chance for your line manager to share your final
              position on the Talent Grid with you. During this conversation,
              you should also share your development goals and career
              aspirations with your line manager and discuss any support you
              might need from them.
            </p>
            <p>
              This is a great opportunity for our line managers to talk to
              colleagues about a whole host of learning materials and
              opportunities we already have at BA, such as the BA Better Leader
              pathways or the A-Z resources on the Leadership & Management
              Centre (more information can be found on ONE).
            </p>
            <br />

            <div style={{ backgroundColor: '#f5f6f7', padding: '5%' }}>
              <h3>Understanding meaningful career conversations</h3>

              <br />
              <p>
                Have a watch of this short 5-minute video to find out more about
                what meaningful career conversations are. You'll discover key
                coaching skills to support your conversations and uncover common
                pitfalls to watch out for.
                <br />
                <br />
                We’ve also created an interactive guide to help you have great
                career conversations – click the download button below the
                video.
                <br />
                <br />
                <div className="card-video" style={{ height: '100%' }}>
                  <video
                    controls
                    preload="metadata"
                    crossorigin="anonymous"
                    controlsList="nodownload noremoteplayback"
                  >
                    <source
                      src="https://apifiles.cappassessments.com/ba/Videos/BA_Meaningful_Career_Conversations.mp4"
                      type="video/mp4"
                    />
                    <track
                      label="English"
                      kind="subtitles"
                      srclang="en"
                      src="https://apifiles.cappassessments.com/ba/Videos/BA_Meaningful_Career_Conversations-en.vtt"
                    />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </p>

              <a
                style={{ lineHeight: '17px' }}
                className="btn btn-primary"
                target="_blank"
                href="https://apifiles.cappassessments.com/ba/Worksheets/Your-guide-to-career-conversations.pdf"
              >
                Career Conversations Guide
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}

export default withAuthenticationRequired(PracticeQuestions, {
  loginOptions: {
    dbConnection: Brand.AUTH_CONNECTION,
    backgroundImage: Brand.SIGN_UP_BG,
    logo: Brand.SIGN_UP_LOGO,
    buttonColor: Brand.SIGN_UP_BUTTON_COLOR,
    buttonHoverColor: Brand.SIGN_UP_BUTTON_HOVER,
    buttonTextColor: Brand.BUTTON_TEXT_COLOR,
    headerColor: Brand.SIGN_UP_HEADER_COLOR,
    welcomeOneColor: Brand.WELCOME_TEXT_COLOR_ONE,
    welcomeTwoColor: Brand.WELCOME_TEXT_COLOR_TWO,
    welcomeTwo: Brand.WELCOME_TEXT,
    appName: Brand.LOG_IN_TITLE,
  },
});
