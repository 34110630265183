exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cognitive-js": () => import("./../../../src/pages/cognitive.js" /* webpackChunkName: "component---src-pages-cognitive-js" */),
  "component---src-pages-complete-js": () => import("./../../../src/pages/complete.js" /* webpackChunkName: "component---src-pages-complete-js" */),
  "component---src-pages-dashboard-js": () => import("./../../../src/pages/dashboard.js" /* webpackChunkName: "component---src-pages-dashboard-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-immersive-assessment-js": () => import("./../../../src/pages/immersive-assessment.js" /* webpackChunkName: "component---src-pages-immersive-assessment-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-learn-about-you-js": () => import("./../../../src/pages/learn-about-you.js" /* webpackChunkName: "component---src-pages-learn-about-you-js" */),
  "component---src-pages-next-steps-js": () => import("./../../../src/pages/next-steps.js" /* webpackChunkName: "component---src-pages-next-steps-js" */),
  "component---src-pages-old-dashbaord-js": () => import("./../../../src/pages/old-dashbaord.js" /* webpackChunkName: "component---src-pages-old-dashbaord-js" */),
  "component---src-pages-practice-questions-js": () => import("./../../../src/pages/practice-questions.js" /* webpackChunkName: "component---src-pages-practice-questions-js" */),
  "component---src-pages-prepare-career-conversation-for-yourself-js": () => import("./../../../src/pages/prepare-career-conversation-for-yourself.js" /* webpackChunkName: "component---src-pages-prepare-career-conversation-for-yourself-js" */),
  "component---src-pages-prepare-career-conversations-js": () => import("./../../../src/pages/prepare-career-conversations.js" /* webpackChunkName: "component---src-pages-prepare-career-conversations-js" */),
  "component---src-pages-review-the-talent-grid-js": () => import("./../../../src/pages/review-the-talent-grid.js" /* webpackChunkName: "component---src-pages-review-the-talent-grid-js" */),
  "component---src-pages-showcase-js": () => import("./../../../src/pages/showcase.js" /* webpackChunkName: "component---src-pages-showcase-js" */),
  "component---src-pages-strengthsbased-js": () => import("./../../../src/pages/strengthsbased.js" /* webpackChunkName: "component---src-pages-strengthsbased-js" */),
  "component---src-pages-tell-us-what-you-thought-js": () => import("./../../../src/pages/tell-us-what-you-thought.js" /* webpackChunkName: "component---src-pages-tell-us-what-you-thought-js" */),
  "component---src-pages-testing-sign-up-js": () => import("./../../../src/pages/testing-sign-up.js" /* webpackChunkName: "component---src-pages-testing-sign-up-js" */),
  "component---src-pages-worksim-js": () => import("./../../../src/pages/worksim.js" /* webpackChunkName: "component---src-pages-worksim-js" */)
}

