import React, { useEffect, useState } from 'react';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { SidebarHeader } from '@capp.micro/shared/components';
import { Helmet } from 'react-helmet';
import Layout from '../components/layout';
import Brand from '../Brand';
import grid from '../images/ba-talent-grid.png';
import { Container, Row, Col } from 'react-bootstrap';
import { getApiUserMetadata } from '@capp.micro/shared/api';

export function PracticeQuestions() {
  const [, setUserMetadata] = useState(null);
  const { user, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    getApiUserMetadata(user, getAccessTokenSilently, setUserMetadata);
  }, []);

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{Brand.PAGE4_TITLE}</title>
      </Helmet>
      <SidebarHeader
        class="sidebar-header page-four capp-gradient"
        heading="Our New Talent Grid"
        subheading="Review"
      />
      <Container className="cards-wrapper">
        <Row>
          <Col xs={12} lg={10}>
            <p className="intro">
              Once you have completed the online survey to measure potential,
              and your performance rating is finalised, you will automatically
              be mapped onto our new Talent Grid.
            </p>
            <p>
              The Talent Grid describes your level of potential to progress to a
              new role and when, as well as how you should be thinking about
              your development at BA over the next 12 - 18 months.
            </p>{' '}
            <br />
            <Row>
              <Col xs={12} lg={6}>
                <img src={grid} style={{ width: '100%' }} />
              </Col>
              <Col xs={12} lg={6}>
                <Row>
                  <Col xs={12}>
                    <p
                      style={{
                        borderTop: '5px solid #e0eaf6',
                        paddingTop: '5px',
                      }}
                    >
                      <strong>Top Talent</strong> - Demonstrates high potential
                      and aspiration to progress at least one level up in their
                      career at BA; highly self-aware and drives their own
                      development; strong capabilities in current role with a
                      desire for more responsibility.
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <p
                      style={{
                        borderTop: '5px solid #e4f0db',
                        paddingTop: '5px',
                      }}
                    >
                      <strong>Talented</strong> - Has mastered current role,
                      operating consistently and effectively; is a subject
                      matter expert with significant knowledge; limited
                      aspiration at present to progress career further or take
                      on additional responsibility.
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <p
                      style={{
                        borderTop: '5px solid #fdf3d0',
                        paddingTop: '5px',
                      }}
                    >
                      <strong>Future Talent</strong> - Capable against our
                      leadership behaviours, with scope to broaden and develop;
                      self-aware and realises strengths and development areas;
                      has aspiration to progress career further at BA; needs
                      stretch and further development to realise full potential.
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <p
                      style={{
                        borderTop: '5px solid #f8e5d8',
                        paddingTop: '5px',
                      }}
                    >
                      <strong>Talent may lie elsewhere</strong> - Requires extra
                      support and targeted development action to reach
                      appropriate technical skills and leadership behaviours
                      required to perform in current role at BA.
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
            <br />
            <br />
            <h3>Why is it important to know where I sit on the Talent Grid?</h3>
            <br />
            <p>
              Knowing your position on the Talent Grid can be useful for many
              reasons, as it:
              <ol style={{ marginTop: '20px' }}>
                <li>
                  Allows you to know what resources and development support
                  could be accessed in order to progress
                </li>
                <li>
                  Helps you and your line manager to understand any
                  opportunities for improvement
                </li>
                <li>Gives you insight and ideas into career progression</li>
              </ol>
            </p>
            <br />
          </Col>
        </Row>
        <Row style={{ marginTop: '20px' }}>
          <Col xs={12} lg={10}>
            <div
              class="sidebar-card quote journeys blue"
              style={{ backgroundColor: '#0277bd', color: 'white' }}
            >
              <h2 style={{ marginBottom: '40px', color: 'white' }}>Remember</h2>
              <p class="quote">
                Your position on the Talent Grid is not permanent, and could
                change from year to year. This is the first year we’ll be
                completing this new Talent Cycle, but this process is something
                that will be happening at BA on an annual basis. Everyone will
                complete the online tool to receive a potential rating each
                year, just in the same way you receive a performance rating each
                year. At the heart of our new BA Talent Cycle are the
                conversations you’ll have with your line managers about your
                strengths, development areas and motivation to grow here at BA.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}

export default withAuthenticationRequired(PracticeQuestions, {
  loginOptions: {
    dbConnection: Brand.AUTH_CONNECTION,
    backgroundImage: Brand.SIGN_UP_BG,
    logo: Brand.SIGN_UP_LOGO,
    buttonColor: Brand.SIGN_UP_BUTTON_COLOR,
    buttonHoverColor: Brand.SIGN_UP_BUTTON_HOVER,
    buttonTextColor: Brand.BUTTON_TEXT_COLOR,
    headerColor: Brand.SIGN_UP_HEADER_COLOR,
    welcomeOneColor: Brand.WELCOME_TEXT_COLOR_ONE,
    welcomeTwoColor: Brand.WELCOME_TEXT_COLOR_TWO,
    welcomeTwo: Brand.WELCOME_TEXT,
    appName: Brand.LOG_IN_TITLE,
  },
});
