import React, { useEffect, useState } from 'react';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { SidebarHeader, } from '@capp.micro/shared/components';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import Layout from '../components/layout';
import Brand from '../Brand';
import { Container, Row, Col } from 'react-bootstrap';
import { getApiUserMetadata } from '@capp.micro/shared/api';

export function PracticeZone() {
  const [userMetadata, setUserMetadata] = useState(null);
  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    getApiUserMetadata(user, getAccessTokenSilently, setUserMetadata);
  }, []);

  const userID = isAuthenticated ? user.sub : 'Unknown';
  const email = isAuthenticated ? encodeURIComponent(user.email) : '';
  const fname = isAuthenticated && userMetadata ? userMetadata.first_name : '';
  const lname = isAuthenticated && userMetadata ? userMetadata.last_name : '';
  const profile =
    isAuthenticated && userMetadata
      ? userMetadata[`${Brand.FLAG_NAME}profileComplete`]
      : '';

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{Brand.PAGE1_TITLE}</title>
      </Helmet>

      <SidebarHeader
        class="sidebar-header page-one capp-gradient"
        heading="Practice Zone"
        subheading="STUDENTS & GRADUATES"
      />

      <Container className="cards-wrapper">
        <Row>
          <Col xs={12} lg={6} style={{ paddingRight: '40px' }}>
            <p className="intro">
              Success starts with true self-awareness and within the Practice
              Zone you can explore and find out more about your values,
              preferences and your learning styles - as well as gain insight
              into how we will look to assess you when you’re ready to apply.
            </p>

            <p>
              If you’re ready to put your studies into practice, then with
              continuous learning, early responsibility and extensive
              networking, our graduate opportunities are a great way to start a
              successful career at Cappfinity.
            </p>
          </Col>
          <Col xs={12} lg={6}>
            <div className="checklist-card">
              <Col lg={12}>
                <h2>What's available...</h2>
              </Col>
              <ol>
                <li>
                  Complete your profile and let us know a bit about you and your
                  experiences to date
                </li>
                <li>
                  Explore examples and practise test questions to learn more
                  about how you will be assessed
                </li>
                <li>
                  Put this learning together and try out one of our practice
                  assessments
                </li>
                <li>Explore our hints and tips section to learn even more</li>
              </ol>
              <motion.button
                className="btn btn-primary"
                variant="primary"
                onClick={(e) =>
                  {window.location.href =
                    isAuthenticated && user && profile
                      ? '#'
                      : Brand.ASSESSMENT_LINKS.PROFILE_LINK +
                        '&cuid=' +
                        userID +
                        '&fname=' +
                        fname +
                        '&lname=' +
                        lname +
                        '&email=' +
                        email +
                        '&auid=' +
                        Brand.AUID;
                        e.target.disabled = "true";
                  }
                }
              >
                Start now
              </motion.button>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}

export default withAuthenticationRequired(PracticeZone, {
  loginOptions: {
    dbConnection: Brand.AUTH_CONNECTION,
    backgroundImage: Brand.SIGN_UP_BG,
    logo: Brand.SIGN_UP_LOGO,
    buttonColor: Brand.SIGN_UP_BUTTON_COLOR,
    buttonHoverColor: Brand.SIGN_UP_BUTTON_HOVER,
    buttonTextColor: Brand.BUTTON_TEXT_COLOR,
    headerColor: Brand.SIGN_UP_HEADER_COLOR,
    welcomeOneColor: Brand.WELCOME_TEXT_COLOR_ONE,
    welcomeTwoColor: Brand.WELCOME_TEXT_COLOR_TWO,
    welcomeTwo: Brand.WELCOME_TEXT,
    appName: Brand.LOG_IN_TITLE,
  },
});
