import React from 'react';
import { SidebarHeader } from '@capp.micro/shared/components';
import { Helmet } from 'react-helmet';
import Layout from '../components/layout';
import Brand from '../Brand';
import { Container, Row, Col } from 'react-bootstrap';
import { withAuthenticationRequired } from '@auth0/auth0-react';

export function HintsAndTips() {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{Brand.PAGE6_TITLE}</title>
      </Helmet>

      <SidebarHeader
        class="sidebar-header page-two capp-gradient"
        heading="Tell us what you thought"
        subheading="British Airways"
      />

      <Container className="cards-wrapper">
        <Row>
          <Col xs={12} lg={9}>
            <p className="intro">
              We hope you found this toolkit a valuable resource to successfully
              complete the Talent Cycle process and to learn more about
              meaningful career conversations.
            </p>

            <p>
              We are very interested in your feedback on what you think went
              well and how we could improve this resource in the future.
            </p>

            <p>
              Please take a few minutes to tell us what you thought by clicking
              the link below.
              <br />
              <br />
              <a
                style={{ lineHeight: '17px' }}
                className="btn btn-primary"
                target="_blank"
                href="https://survey.alchemer.eu/s3/90534567/British-Airways-Talent-Potential-Toolkit"
              >
                Tell us what you thought
              </a>
            </p>

            <br />
            <br />
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}

export default withAuthenticationRequired(HintsAndTips, {
  loginOptions: {
    dbConnection: Brand.AUTH_CONNECTION,
    backgroundImage: Brand.SIGN_UP_BG,
    logo: Brand.SIGN_UP_LOGO,
    buttonColor: Brand.SIGN_UP_BUTTON_COLOR,
    buttonHoverColor: Brand.SIGN_UP_BUTTON_HOVER,
    buttonTextColor: Brand.BUTTON_TEXT_COLOR,
    headerColor: Brand.SIGN_UP_HEADER_COLOR,
    welcomeOneColor: Brand.WELCOME_TEXT_COLOR_ONE,
    welcomeTwoColor: Brand.WELCOME_TEXT_COLOR_TWO,
    welcomeTwo: Brand.WELCOME_TEXT,
    appName: Brand.LOG_IN_TITLE,
  },
});
