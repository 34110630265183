import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';

export const ItemComponent = ({ selectedItem, handleClickPage, selectedPageId }) => {

ItemComponent.propTypes = {
  selectedItem: PropTypes.object,
  handleClickPage: PropTypes.func,
  selectedPageId: PropTypes.string
}

  if (!selectedItem?.pages) {return <p>No item or pages selected</p>;}
  
  return (
    <Container className="dashboard-container item">
      <Row>

        <Col xs={12} className='item-container'>
          <h2 className='item-title'>{selectedItem.title}</h2>
          {selectedItem.pages.map((page, index) => {
              let pageClass = 'page-item phase-module-item';

              if (page.id === selectedPageId) {
                  pageClass += ' selected-item';
              }

              const handleClick = () => handleClickPage(page);

              if (index !== 0) {
                      pageClass += ' completed';
              }

              return (
                  <button 
                      className={pageClass}
                      key={page.number}
                      onClick={handleClick}

                  >
                      <span className='page-number'>{page.number}</span>
                      <h2>{page.title}</h2>
                  </button>
              );
          })}
      </Col>

      </Row>
    </Container>
  );
};