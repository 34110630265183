import React from 'react';
import {Row, Col} from 'react-bootstrap';
import badge30locked from './badge-1-locked.svg';
import badge60locked from './badge-2-locked.svg';
import badge90locked from './badge-3-locked.svg';
import badge30unlocked from './badge-1-unlocked.svg';
import badge60unlocked from './badge-2-unlocked.svg';
import badge90unlocked from './badge-3-unlocked.svg';
import PropTypes from 'prop-types';

export const ProgressDashboard = ({progressObject}) => {
  ProgressDashboard.propTypes = {
    progressObject: PropTypes.object
  }
    return (
        <Col
            xs={12}
            lg={3}
            className="dashboard-sidebar-col"
            style={{ marginLeft: '0px' }}
          >
            <div className="dashboard-sidebar">
              <h2>
                Welcome{' '}
                <span className="wave" role="img" aria-label="Waving hand">
                👋
                </span>{' '}
              </h2>
              <div
                className="dashboard-progress"
                style={{ marginBottom: '0px' }}
              >
                <h2>Progress</h2>
                <p>{progressObject[0].toString()}% achieved</p>
              </div>
              <Row style={{ padding: '0', maxWidth: '100%' }}>
                <Col xs={4} style={{ padding: '0' }}>
                  <div
                    className="dashboard-progress phase"
                    style={{ width: '95%' }}
                  >
                    <Row style={{ padding: '0', maxWidth: '100%' }}>
                      <Col xs={1} style={{ padding: '0' }}>
                        <span className="phase-progress-line one" />
                      </Col>
                      <Col
                        xs={11}
                        style={{ paddingLeft: '6px', paddingRight: '0px' }}
                      >
                        <p className="phase-progress-percentage">
                          {progressObject[1].toString()}%
                        </p>
                        <p className="phase-progress-name">Phase 1</p>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col xs={4} style={{ padding: '0' }}>
                  <div
                    className="dashboard-progress phase"
                    style={{ width: '95%' }}
                  >
                    <Row style={{ padding: '0', maxWidth: '100%' }}>
                      <Col xs={1} style={{ padding: '0' }}>
                        <span className="phase-progress-line two" />
                      </Col>
                      <Col
                        xs={11}
                        style={{ paddingLeft: '6px', paddingRight: '0px' }}
                      >
                        <p className="phase-progress-percentage">
                          {progressObject[2].toString()}%
                        </p>
                        <p className="phase-progress-name">Phase 2</p>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col xs={4} style={{ padding: '0' }}>
                  <div
                    className="dashboard-progress phase"
                    style={{ width: '95%' }}
                  >
                    <Row style={{ padding: '0', maxWidth: '100%' }}>
                      <Col xs={1} style={{ padding: '0' }}>
                        <span className="phase-progress-line three" />
                      </Col>
                      <Col
                        xs={11}
                        style={{ paddingLeft: '6px', paddingRight: '0px' }}
                      >
                        <p className="phase-progress-percentage">
                          {progressObject[3].toString()}%
                        </p>
                        <p className="phase-progress-name">Phase 3</p>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
              <h2 style={{ marginTop: '20px' }}>Achievements</h2>
              <Row
                style={{ padding: '0', maxWidth: '100%', marginTop: '10px' }}
              >
                <Col xs={4} style={{ padding: '0' }}>
                  <Row style={{ padding: '0', maxWidth: '100%' }}>
                    <Col xs={12}>
                      <img src={progressObject[1] === 100 ? badge30unlocked : badge30locked} alt="30 days badge" />
                    </Col>
                  </Row>
                </Col>
                <Col xs={4} style={{ padding: '0' }}>
                  <Row style={{ padding: '0', maxWidth: '100%' }}>
                    <Col xs={12}>
                      <img src={progressObject[2] === 100 ? badge60unlocked : badge60locked} alt="60 days badge" />
                    </Col>
                  </Row>
                </Col>
                <Col xs={4} style={{ padding: '0' }}>
                  <Row style={{ padding: '0', maxWidth: '100%' }}>
                    <Col xs={12}>
                      <img src={progressObject[3] === 100 ? badge90unlocked : badge90locked} alt="90 days badge" />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Col>
    )
};