import React from "react";
import { Modal, Container, Row, Col } from "react-bootstrap";
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';


const badgeVariants = {
    hidden: {
      scale: 0,
      opacity: 0,
    },
    visible: {
      scale: 1,
      opacity: 1,
      transition: {
        type: 'spring',
        stiffness: 260,
        damping: 20,
        delay: 0.1,
      },
    },
  };

  

// Extract the CompletionModal Component
export const CompletionModal = ({
    show,
    onHide,
    size,
    title,
    centered,
    backdrop,
    className,
    showBadge,
    badgeImage,
    phase,
    modalHeader,
    modalContent,
    completionMessage,
    completionLink,
  }) => {
    CompletionModal.propTypes =
    {    show: PropTypes.bool,
         onHide: PropTypes.func,
         size: PropTypes.string,
         title: PropTypes.string,
         centered: PropTypes.bool,
         backdrop: PropTypes.string,
         className: PropTypes.string,
         showBadge: PropTypes.bool,
         badgeImage: PropTypes.string,
         phase: PropTypes.object,
         modalHeader: PropTypes.string,
         modalContent: PropTypes.string,
         completionMessage: PropTypes.string,
         completionLink: PropTypes.object}
    return (
    <Modal
      show={show}
      onHide={onHide}
      size={size}
      aria-labelledby={title}
      centered={centered}
      backdrop={backdrop}
      className={className}
    >
      {showBadge && (
        <Modal.Header>
        
          <motion.img
            src={badgeImage}
            alt={`Completion Badge for Phase ${phase.id}`}
            initial="hidden"
            animate="visible"
            variants={badgeVariants}
            className="badge-img"
          />
          <Modal.Title></Modal.Title>
        </Modal.Header>
      )}
      <Modal.Body>
        <Container>
          <Row>
            {showBadge ? (
              <Col xs={12}>
                <h2>{modalHeader}</h2>
                <p className="completion-message">{modalContent}</p>
              </Col>
            ) : (
              <Col xs={12}>
                <h2>{completionMessage}</h2>
              </Col>
            )}
          </Row>
          <Row>
            <Col xs={12}>
              <div className="page-navigation">{completionLink}</div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  )};