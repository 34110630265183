import React, { useEffect, useState } from 'react';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { SidebarHeader } from '@capp.micro/shared/components';
import { Helmet } from 'react-helmet';
import Layout from '../components/layout';
import Brand from '../Brand';
import { Container, Row, Col } from 'react-bootstrap';
import { getApiUserMetadata } from '@capp.micro/shared/api';

export function PracticeQuestions() {
  const [, setUserMetadata] = useState(null);
  const { user, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    getApiUserMetadata(user, getAccessTokenSilently, setUserMetadata);
  }, []);

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{Brand.PAGE3_TITLE}</title>
      </Helmet>
      <SidebarHeader
        class="sidebar-header page-four capp-gradient"
        heading="Conversation for yourself"
        subheading="Prepare"
      />
      <Container className="cards-wrapper">
        <Row>
          <Col xs={12} lg={7}>
            <p className="intro">
              For colleagues, career conversations are about growth, development
              and setting goals for future opportunities and differ from
              performance conversations.
            </p>

            <p>
              Each colleague will have two different conversations with your
              line manager during the Talent Cycle –
            </p>

            <br />
            <h3>UNDERSTANDING MEANINGFUL CAREER CONVERSATIONS</h3>

            <br />
            <p>
              It is recommended that colleagues watch the ‘Meaningful Career
              Conversations’ video on the previous page – this is a short,
              5-minute video to find out more about what meaningful career
              conversations are, explore key coaching skills to support your
              conversations and uncover common pitfalls to watch out for.
            </p>
            <p>
              Then explore the guide to ‘Preparing for your career conversation’
              designed to help colleagues get the most from these conversations.
            </p>
          </Col>
          <Col xs={12} lg={5}>
            <Row>
              <Col xs={12}>
                <div class="sidebar-card quote journeys">
                  <span class="quote-sign">
                    Preparing for the Growing Your Career conversation
                  </span>
                  <p class="quote">
                    View your journey <a href="#">here</a>
                  </p>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}

export default withAuthenticationRequired(PracticeQuestions, {
  loginOptions: {
    dbConnection: Brand.AUTH_CONNECTION,
    backgroundImage: Brand.SIGN_UP_BG,
    logo: Brand.SIGN_UP_LOGO,
    buttonColor: Brand.SIGN_UP_BUTTON_COLOR,
    buttonHoverColor: Brand.SIGN_UP_BUTTON_HOVER,
    buttonTextColor: Brand.BUTTON_TEXT_COLOR,
    headerColor: Brand.SIGN_UP_HEADER_COLOR,
    welcomeOneColor: Brand.WELCOME_TEXT_COLOR_ONE,
    welcomeTwoColor: Brand.WELCOME_TEXT_COLOR_TWO,
    welcomeTwo: Brand.WELCOME_TEXT,
    appName: Brand.LOG_IN_TITLE,
  },
});
