import React, { useEffect, useState } from 'react';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { SidebarHeader, SidebarCard } from '@capp.micro/shared/components';
import { Helmet } from 'react-helmet';
import Layout from '../components/layout';
import Brand from '../Brand';
import { Container, Row, Col } from 'react-bootstrap';
import { getApiUserMetadata } from '@capp.micro/shared/api';

export function PracticeQuestions() {
  const [userMetadata, setUserMetadata] = useState(null);
  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();
  
  useEffect(() => {
    getApiUserMetadata(user, getAccessTokenSilently, setUserMetadata);
  }, []);

  const userID = isAuthenticated ? user.sub : 'Unknown';
  const email = isAuthenticated ? encodeURIComponent(user.email) : '';
  const fname = isAuthenticated && userMetadata ? userMetadata.first_name : '';
  const lname = isAuthenticated && userMetadata ? userMetadata.last_name : '';

  const step4 =
    isAuthenticated && userMetadata
      ? userMetadata[`${Brand.FLAG_NAME}step4Complete`]
      : '';
  const step5 =
    isAuthenticated && userMetadata
      ? userMetadata[`${Brand.FLAG_NAME}step5Complete`]
      : '';
  const step6 =
    isAuthenticated && userMetadata
      ? userMetadata[`${Brand.FLAG_NAME}step6Complete`]
      : '';

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{Brand.PAGE3_TITLE}</title>
      </Helmet>
      <SidebarHeader
        class="sidebar-header page-three capp-gradient"
        heading="Practice Questions"
        subheading="STUDENTS & GRADUATES"
      />
      <Container className="cards-wrapper">
        <Row>
          <Col xs={12} lg={4}>
            <SidebarCard
              alt={Brand.BRAND_NAME}
              class="sidebar-card card-four"
              heading={['Practice Work Simulation', <br />, <br />]}
              text={[
                'Experience a generic work simulation and how you may be tested when applying for jobs.',
              ]}
              buttonText={
                isAuthenticated && user && step4 ? 'Retake' : 'Start Now'
              }
              link={
                Brand.ASSESSMENT_LINKS.WORK_SIM_LINK +
                '&cuid=' +
                userID +
                '&fname=' +
                fname +
                '&lname=' +
                lname +
                '&email=' +
                email +
                '&auid=' +
                Brand.AUID
              }
            />
          </Col>
          <Col xs={12} lg={4}>
            <SidebarCard
              alt={Brand.BRAND_NAME}
              class="sidebar-card card-five"
              heading={['Practice Cognitive Assessments', <br />, <br />]}
              text={[
                'Experience examples of specific numerical, verbal and critical reasoning questions types.',
              ]}
              buttonText={
                isAuthenticated && user && step5 ? 'Retake' : 'Start Now'
              }
              link={
                Brand.ASSESSMENT_LINKS.COG_LINK +
                '&cuid=' +
                userID +
                '&fname=' +
                fname +
                '&lname=' +
                lname +
                '&email=' +
                email +
                '&auid=' +
                Brand.AUID
              }
            />
          </Col>

          <Col xs={12} lg={4}>
            <SidebarCard
              alt={Brand.BRAND_NAME}
              class="sidebar-card card-six"
              heading={['Practice Video Interview', <br />, <br />]}
              text={[
                'Complete this full scale practice assessment to get the closest insight into what a real assessment will be like.',
              ]}
              buttonText={
                isAuthenticated && user && step6 ? 'Retake' : 'Start Now'
              }
              link={
                Brand.ASSESSMENT_LINKS.TRIAL_LINK +
                '&cuid=' +
                userID +
                '&fname=' +
                fname +
                '&lname=' +
                lname +
                '&email=' +
                email +
                '&auid=' +
                Brand.AUID
              }
            />
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}

export default withAuthenticationRequired(PracticeQuestions, {
  loginOptions: {
    dbConnection: Brand.AUTH_CONNECTION,
    backgroundImage: Brand.SIGN_UP_BG,
    logo: Brand.SIGN_UP_LOGO,
    buttonColor: Brand.SIGN_UP_BUTTON_COLOR,
    buttonHoverColor: Brand.SIGN_UP_BUTTON_HOVER,
    buttonTextColor: Brand.BUTTON_TEXT_COLOR,
    headerColor: Brand.SIGN_UP_HEADER_COLOR,
    welcomeOneColor: Brand.WELCOME_TEXT_COLOR_ONE,
    welcomeTwoColor: Brand.WELCOME_TEXT_COLOR_TWO,
    welcomeTwo: Brand.WELCOME_TEXT,
    appName: Brand.LOG_IN_TITLE,
  },
});
