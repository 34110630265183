import React, { useEffect, useState } from 'react';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { JumboTwoColsLeft } from '@capp.micro/shared/components';
import Layout from '../components/layout';
import Brand from '../Brand';
import showcase from '../images/showcase.svg';
import SubHero from '../components/subHero';
import { getApiUserMetadata } from '@capp.micro/shared/api';

export function Showcase() {
  const [userMetadata, setUserMetadata] = useState(null);
  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();
  
  useEffect(() => {
    getApiUserMetadata(user, getAccessTokenSilently, setUserMetadata);
  }, []);

  const userID = isAuthenticated ? user.sub : 'Unknown';
  const email = isAuthenticated ? encodeURIComponent(user.email) : '';
  const fname = isAuthenticated && userMetadata ? userMetadata.first_name : '';
  const lname = isAuthenticated && userMetadata ? userMetadata.last_name : '';

  return (
    <Layout>
      <SubHero
        welcomeTitle="The"
        title="Showcase"
        text=""
        image={showcase}
        class="jumbo-section hero full-width hero-light capp-gradient sub-hero"
      />

      <JumboTwoColsLeft
        text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
        buttonText="Start now"
        link={
          Brand.ASSESSMENT_LINKS.SHOWCASE_ASSESS_LINK +
          '&cuid=' +
          userID +
          '&fname=' +
          fname +
          '&lname=' +
          lname +
          '&email=' +
          email +
          '&auid=104d7123-6216-4c60-8449-28bc31ff77ce'
        }
        class="mt-3 mb-10 brand-discover jumbo-section full-width"
      />
    </Layout>
  );
}

export default withAuthenticationRequired(Showcase, {
  loginOptions: {
    dbConnection: Brand.AUTH_CONNECTION,
    backgroundImage: Brand.SIGN_UP_BG,
    logo: Brand.SIGN_UP_LOGO,
    buttonColor: Brand.SIGN_UP_BUTTON_COLOR,
    buttonHoverColor: Brand.SIGN_UP_BUTTON_HOVER,
    buttonTextColor: Brand.BUTTON_TEXT_COLOR,
    headerColor: Brand.SIGN_UP_HEADER_COLOR,
    welcomeOneColor: Brand.WELCOME_TEXT_COLOR_ONE,
    welcomeTwoColor: Brand.WELCOME_TEXT_COLOR_TWO,
    welcomeTwo: Brand.WELCOME_TEXT,
    appName: Brand.LOG_IN_TITLE,
  },
});
