import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import circle from './colour-circle-bg.png';
import { Container, Row, Col } from 'react-bootstrap';
import getApiUserMetadata from '../../api/getApiUserMetadata';
import { PrepModal, ProgressDashboard, LearningPathPhases, RecommendedForYou, DashboardLayout } from '@capp.micro/shared/components';
import data from "../data/data";
import PropTypes from "prop-types"

export const DashboardPage = ({logo, Brand, user, getAccessTokenSilently }) => {
    DashboardPage.propTypes = {
        Brand: PropTypes.object,
        logo: PropTypes.string,
        user: PropTypes.object, 
        getAccessTokenSilently: PropTypes.func, 
      }
  const [modalShow, setModalShow] = React.useState(false);
  const phases = data.phases;

  function parsePage(page) {
    const results = [];

    if (page.responseType) {
      if (page.resourceType) {
        page.resourceType.forEach((resourceItem) => {
          results.push(
            `${resourceItem.id}`
          );
        });
      }
      page.responseType.forEach((responseItem) => {
        results.push(responseItem.guid);
      });
    } else {
      results.push(
        `${page.resourceType[0].id}`
      );
    }

    return results.filter(individualItem => individualItem !== undefined);
  }

  const flattenItems = (module) => {
    return module.items
          .map((item) => {
            return item.pages.flatMap((page) => {
              return parsePage(page);
            });
          })
          .flat();
  }

  const allComponents = data.phases.map((phase) => {
    return phase.modules
      .map((module) => {
        return flattenItems(module);
      })
      .flat();
  });

  const [userMetadata, setUserMetadata] = useState({});

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        await getApiUserMetadata(user, getAccessTokenSilently, setUserMetadata);
      } catch (error) {
        throw new Error('Error fetching user metadata');
      }
    };
    fetchQuestions();
  }, [user, getAccessTokenSilently, setUserMetadata]);

  function isSubArrayStringPresent(subArray, obj) {
    return subArray.map((str) =>
      Object.keys(obj).some((key) => key.includes(str))
    );
  }

  const completed = allComponents.map((subArray) =>
    isSubArrayStringPresent(subArray, userMetadata)
  );

  const phaseCounts = [0, 0, 0];
  function updatePhases(subArray, index) {
    const count = subArray.filter((item) => item).length;
    phaseCounts[index] += count;
  }
  completed.forEach((subArray, index) => {
    updatePhases(subArray, index);
  });
  const progressObject = {
    0: Math.floor(
      (100 / completed.flat().length) *
        (phaseCounts[0] + phaseCounts[1] + phaseCounts[2])
    ),
    1: Math.floor((100 / completed[0].length) * phaseCounts[0]),
    2: Math.floor((100 / completed[1].length) * phaseCounts[1]),
    3: Math.floor((100 / completed[2].length) * phaseCounts[2]),
  };

  return <> {!userMetadata ? (
    ''
  ) : (
    <DashboardLayout brand={Brand} logo={logo}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{Brand.PAGE1_TITLE}</title>
      </Helmet>
      <PrepModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        videoUrl="https://apifiles.cappassessments.com/yourco/videos/prep-plus-intro.mp4"
        videoTitle=""
      />

      <Container
        className="cards-wrapper dashboard-container"
        style={{ paddingTop: '0rem' }}
      >
        <Row>
          <Col xs={12} lg={8} className="phase-container">
            <Row>
              <Col
                xs={12}
               
                style={{ marginBottom: '40px', position: 'relative' }}
              >
                <div className="dashboard-intro">
                  <h3>
                    Welcome to your toolkit which will support you during the
                    first 90 days of your new role
                  </h3>
                </div>
                <div className="colour-bg">
                  <img src={circle} alt="Coloured circle" />
                </div>
              </Col>
              {/* <Col xs={12} lg={4}>
                <div className="dashboard-intro continue">
                  <h3>Continue where you left off</h3>
                </div>
              </Col> */}
            </Row>
            <LearningPathPhases
              progressObject={progressObject}
              phases={phases}
            ></LearningPathPhases>
            <RecommendedForYou />
          </Col>

          <ProgressDashboard progressObject={progressObject} />
        </Row>
      </Container>
    </DashboardLayout>
  )}</>
};

export default DashboardPage;
