import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from 'react-bootstrap';
import { ModuleLayout } from "../moduleLayout/moduleLayout";
import { ItemComponent } from "../itemComponent/itemComponent";
import { PageComponent } from "../pageComponent/pageComponent";
import { CompletionModal } from "../completionModal/completionModal";
import { getApiUserMetadata } from "@capp.micro/shared/api";
import returnResources from "./resourceFunction";
import phase1Badge from './badge-1-unlocked.svg';
import phase2Badge from './badge-2-unlocked.svg';
import phase3Badge from './badge-3-unlocked.svg';
import PropTypes from 'prop-types';

const getBadgeImage = (phaseId) => {
  switch (phaseId) {
    case 1:
      return phase1Badge;
    case 2:
      return phase2Badge;
    case 3:
      return phase3Badge;
    default:
      return null;
  }
};

export const ModuleTemplateShared = ({ pageContext, Brand, user, getAccessTokenSilently }) => {

  ModuleTemplateShared.propTypes = {
    pageContext: PropTypes.object,
    Brand: PropTypes.object,
    user: PropTypes.object, 
    getAccessTokenSilently: PropTypes.func, 
  }
  
  const { module, phase, kitLength } = pageContext
  const [userMetadata, setUserMetadata] = useState({});
  const [modalShow, setModalShow] = useState(false)
  const [completionLink, setCompletionLink] = useState(null)
  const [completionMessage, setCompletionMessage] = useState('');
  const [showBadge, setShowBadge] = useState(false);
  const [modalHeader, setModalHeader] = useState('');
  const [modalContent, setModalContent] = useState('');
  const modalClassName = showBadge
    ? 'module-modal badge-modal'
    : 'module-modal';

  useEffect(()=> {
     getApiUserMetadata(user, getAccessTokenSilently, setUserMetadata);
    }, [user, getAccessTokenSilently])

  const [selectedPage, setSelectedPage] = useState(null);

  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedPageIndex, setSelectedPageIndex] = useState(0);


  useEffect(() => {
    if (module.items && module.items.length > 0) {
        setSelectedItem(module.items[0]);
        setSelectedPage(module.items[0].pages[0]);
        setSelectedPageIndex(0);
    }
  }, [module]);
  
  const handleClickPage = (page) => {
    setSelectedPage(page);

    if (selectedItem?.pages) {
        const newIndex = selectedItem.pages.indexOf(page);
        setSelectedPageIndex(newIndex);
    }
  };

  const handleNextPage = () => {
    if (selectedItem?.pages && selectedPageIndex < selectedItem.pages.length - 1) {
        setSelectedPageIndex(prevIndex => {
            const newIndex = prevIndex + 1;
            setSelectedPage(selectedItem.pages[newIndex]);
            return newIndex;
        });    
    }
    window.scrollTo(0, 0);

    }

    const handlePrevPage = () => {
        if (selectedItem?.pages && selectedPageIndex > 0) {
            setSelectedPageIndex(prevIndex => {
                const newIndex = prevIndex - 1;
                setSelectedPage(selectedItem.pages[newIndex]);
                return newIndex;
            });

        }
        window.scrollTo(0, 0);
    }

    const handleCompletion = () => {
      setModalShow(false);
     if (phase.id === kitLength && module.id === phase.modules.length) {
      setCompletionLink(<Link className="btn-primary" to={`/dashboard`}>Complete 90DTK</Link>);
      setCompletionMessage(
        'Congratulations! You have completed the entire 90DTK.'
      );
    } else if (phase.id < kitLength && module.id === phase.modules.length) {
      setCompletionLink(<Link className="btn-primary" to={`/dashboard`}>Go to next phase</Link>);
      setCompletionMessage(
        `You've completed Phase ${phase.id}! Move on to Phase ${phase.id + 1}.`
      );
    } else {
      setCompletionLink(<Link className="btn-primary" to={`/phases/${phase.id}`}>Go to next module</Link>);
      setCompletionMessage(
        `You've completed Module ${module.id} of Phase ${
          phase.id
        }! Move on to Module ${module.id + 1}.`
      );
    }
    setModalShow(true);
    if (module.id === phase.modules.length) {
      setShowBadge(true);
      setModalHeader(`Congratulations on Completing Phase ${phase.id}!`);
      setModalContent("You've unlocked your next badge");
    }
    }

 let allComponents = null;
 let completed = null;

 function isSubArrayStringPresent(subArray, obj) {
  return subArray.map(str => Object.keys(obj).some(key => key.includes(str)));
}
 
    if (selectedItem) {
       allComponents = selectedItem.pages.map((page) =>{
        return returnResources(page)
      }
  );
  
   completed = allComponents.map(subArray =>
    isSubArrayStringPresent(subArray, userMetadata)
  );
    }

    const badgeImage = getBadgeImage(phase.id);

  return (

    <ModuleLayout>

      <Container className='module-nav'>
        <Row>
            <Col>
              <Link to={`/phases/${phase.id}`}><span />Back to Overview</Link>
            </Col>
        </Row>
      </Container>
     <Container>

     </Container>
      <Container className="module-container">
        
           
            {module.items.map(item => (
                
                <Row key={item.id}>
                
                    <Col xs={12} lg={3} className="phase-outline">
                      <div className="phase-outline-details">
                        <h1>Phase {phase.number}</h1>  
                        <h2>{phase.name}</h2>
                      </div>
                        <ItemComponent 
                            key={item.id} 
                            selectedItem={item} 
                            handleClickPage={handleClickPage}
                            phaseNumber={phase.number}
                            moduleNumber={module.number}
                            selectedPageId={selectedPage ? selectedPage.id : null}
                            userMetadata={userMetadata}
                        />
                     </Col>
                    
                     
                     <Col xs={12} lg={9}>
                        {selectedPage && 
                        <>
                            <PageComponent
                              brand={Brand}
                              selectedPage={selectedPage} 
                              phaseNumber={phase.number}
                              moduleNumber={module.number}
                              setUserMetadata={setUserMetadata}
                            />
                          
                            <div className="page-navigation">
                              <button className="btn-primary previous" onClick={handlePrevPage} disabled={selectedPageIndex === 0}>Previous</button>
                              {selectedPageIndex === completed.length - 1 ? (<button className="btn-primary next" onClick={handleCompletion}>Complete Module</button>) : (<button className="btn-primary next" onClick={handleNextPage}>Next</button>)}
                            </div>
                            
                        </>
                        
                        }
                     </Col>
                </Row>
            ))}
       <CompletionModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          size="lg"
          title="contained-modal-title-vcenter"
          centered
          backdrop="static"
          className={modalClassName}
          showBadge={showBadge}
          badgeImage={badgeImage}
          phase={phase}
          modalHeader={modalHeader}
          modalContent={modalContent}
          completionMessage={completionMessage}
          completionLink={completionLink}
        />
      </Container>
      </ModuleLayout>
  
  )
}

export default ModuleTemplateShared;
