import React from "react"
import { Navigation } from "../../navigation/navigation";
import PropTypes from 'prop-types';

export const HeaderTwo = ({ isAuthenticated, brand, logo }) => {
  HeaderTwo.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    logo: PropTypes.string.isRequired,
    brand: PropTypes.object.isRequired,
  }

  return (
    <header
      style={{
        marginBottom: `1.45rem`,
        display: `flex`,
        displayDirection: `column`
      }}
    >
     {isAuthenticated ? ( <div  style={{
          margin: `0 auto`,
          width: `100%`,
          minWidth: `100px`,
          padding: `1.45rem 0`,
          height: `65px`
        }}>
        <Navigation
        useApiEndpoints
          logo={logo}
          alt={brand?.LOGO_ALT}
        />
        
      </div>) :  (<div
        style={{
          margin: `0 auto`,
          width: `100%`,
          maxWidth: `100%`,
          padding: `1.45rem 0`
        }}
      >
        <Navigation
        useApiEndpoints
          logo={logo}
          alt={brand?.LOGO_ALT}
        />
        
      </div>)}
    </header>
  )
};