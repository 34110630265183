import React from "react"
import { Col, Container, Row, } from "react-bootstrap"

export function Hero(props) {
  return (
      <Container className={props.class} style={{ zIndex: "2" }}>
        <Row>
          <Col xs={12} lg={6} className="text-container">
            <h3>{props.welcomeTitle}</h3>
            <h1>{props.title}</h1>
            <h2> {props.subtitle}</h2>
          </Col>
          <Col xs={12} lg={6} className="jumbo-right-image">
            <img src={props.image} alt={props.alt}/>

          </Col>
        </Row>
      </Container>
  )
}

export default Hero
