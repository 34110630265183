import React, {useState, useEffect} from "react"
import { Link } from 'gatsby';
import { PhaseLayout } from "../phaseLayout/phaseLayout";
import { Container, Row, Col } from 'react-bootstrap';
import { getApiUserMetadata } from "@capp.micro/shared/api";
import returnResources from "./resourceFunction";
import data from "../data/data";
import PropTypes from 'prop-types';

export const PhaseTemplateShared = ({ pageContext, user, getAccessTokenSilently }) => {

  PhaseTemplateShared.propTypes = {
    pageContext: PropTypes.object,
    user: PropTypes.object, 
    getAccessTokenSilently: PropTypes.func, 
  }
  
  const { phase } = pageContext

  const phases = data.phases;

  const flattenPages = (item) => {
    item.pages.flatMap((page) => {
      return returnResources(page)
    })
  }
  const allComponents = phases.map((individualPhase) =>
  individualPhase.modules.map((module) =>
    module.items.flatMap((item) =>
      flattenPages(item)
    )
  )
);

  const [userMetadata, setUserMetadata] = useState({});

  useEffect(()=> {
    const fetchQuestions = async () => {
      try {
        await getApiUserMetadata(user, getAccessTokenSilently, setUserMetadata);
      } catch (error) {
        throw new Error('Error fetching user metadata');
      }
    }
    fetchQuestions();
    }, [user, getAccessTokenSilently, setUserMetadata])
    
    function isSubArrayStringPresent(subArray, obj) {
      return subArray.map(str => Object.keys(obj).some(key => key.includes(str)));
    }
    
    const completed = allComponents.map(array =>
      array.map(subArray => isSubArrayStringPresent(subArray, userMetadata))
    ).map(array =>
      array.map(subArray => !subArray.includes(false))
    );

    const moduleUnlock = (module, index) => {
        return (<Row>
          <Col xs={12}>
          <div className="phase-intro module">  
            <div>
              <h2>{module.name}</h2> 
              <p>{module.description}</p>
            </div>
            <div>
            <Link className="btn-primary" to={`/phases/${phase.id}/modules/${module.id}`}>{completed[phase.id - 1][index] === false ? 'Start' : 'Completed'}</Link>
            </div>
          </div>
          </Col>
        </Row>)

    }
 
      const displayDescription = () => {
        if (phase.expandedDescription) {
          const expandedDescription = phase.expandedDescription;
          const renderedContent = [];
          Object.keys(expandedDescription).forEach((key) => {
            const item = expandedDescription[key];
            if (Array.isArray(item)) {
              const mappedTexts = item.map((text) => (
                <li key={text}>{text}</li>
              ));
              const fullList = <ul>{mappedTexts}</ul>
              renderedContent.push(fullList);
            } else {
              renderedContent.push(<div key={key}>{item}</div>);
            }
          });
          return renderedContent;
        } else {
          return (<div></div>)
        }
      };

  return (
    <PhaseLayout>
      <Container className='phase-nav'>
        <Row>
            <Col><Link to={`/dashboard`}><span />Back to dashboard</Link></Col>
        </Row>
      </Container>
     <Container className="phase-list-container">
      <Row style={{marginBottom: "40px"}}>
        
          <Col xs={12} lg={5}>
            <div className="phase-overview">
              <div className='phase-cover'  style={{backgroundImage: `url(${phase.image})`, backgroundPosition: "right", }}>
              <div className="phase-details overview">{displayDescription()}</div>
              </div>
            </div>
          </Col>
          <Col xs={12} lg={7} style={{position: "relative"}}>
          {phase.modules.map((module, index) => (
         moduleUnlock(module, index)
          ))}
          </Col>
        
        </Row>
      </Container>
    </PhaseLayout>
  )
}

export default PhaseTemplateShared;
