import React from 'react';
import PropTypes from 'prop-types';
import { HeaderTwo } from '@capp.micro/shared/components';
import { Col, Container, Row } from 'react-bootstrap';
import { useAuth0 } from '@auth0/auth0-react';
import { Helmet } from 'react-helmet';

export const DashboardLayout = ({ children, brand, logo }) => {
  const isBrowser = typeof window !== 'undefined';

  const { isAuthenticated} = useAuth0();
  
  return (
    <>
      
      <Helmet
        htmlAttributes={{
          lang: 'en',
          'xmlns:og': 'https://ogp.me/ns#',
          'xmlns:fb': 'https://ogp.me/ns/fb#',
        }}
      >
        <meta charSet="utf-8" />
        <meta content="width=device-width, initial-scale=1.0" name="viewport" />
        ....
      </Helmet>

      <HeaderTwo isAuthenticated={isAuthenticated} Brand={brand} logo={logo}/>
  
      {isBrowser && window.location.pathname === '/' && (
        
          <main>{children}</main>
         
        
      )}
      {isBrowser && window.location.pathname === '/testing-sign-up' && (
    
          <main>{children}</main>
         
  
      )}

      {isBrowser && window.location.pathname !== '/' && (
        <div
          style={{
            margin: `0 auto`,
            height: '100vh',
            width: '100%',
            padding: '0',
          }}
        >
          <main>
            
            
            
            <Container style={{ maxWidth: '100%', padding: '0' }}>
        
              <Row style={{ maxWidth: '100%', padding: '0' }}>
                <Col
                  className="main"
                  xs={12}
                >
                  {children}

                </Col>
                
              </Row>
            </Container>
          </main>
        </div>
      )}
      
      
    </>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.node.isRequired,
  brand: PropTypes.object.isRequired,
  logo: PropTypes.string.isRequired,
};