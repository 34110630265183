import React from "react"
import { Navigation } from "@capp.micro/shared/components";
import logo from "../images/brand-logo.svg"
import Brand from "../Brand"
export function Header() {
  return (
    <header
      style={{
        marginBottom: `1.45rem`,
      }}
    >
      <div
        style={{
          margin: `0 auto`,
          maxWidth: `100%`,
          padding: `1.45rem 0`,
        }}
      >
        <Navigation
          logo={logo}
          alt={Brand.LOGO_ALT}
        />
      </div>
    </header>
  )
}

export default Header
