import React from 'react';
import { Link } from 'gatsby';
import {Col, Row} from 'react-bootstrap';
import ted from './ted-logo.png';

export const RecommendedForYou = () => {

    return (
        <>
        <Row>
              <Col xs={12} style={{ marginBottom: '20px' }}>
                <h2>Recommended for you</h2>
              </Col>
            </Row>
            <Row>
              <Col xs={12} lg={4}>
                <div className="recommended-tile">
                  <div className="recommended-text">
                    <h2>LEARN</h2>
                    <p>The benefits of Strengths</p>
                  </div>
                  <div className="recommended-button">
                    <Link className="btn btn-primary" to="https://www.strengthsprofile.com/en-gb/blog/post/25/the-benefits-of-learning-our-strengths" target="_blank">
                      Learn more
                    </Link>
                  </div>
                </div>
              </Col>
              <Col xs={12} lg={4}>
              <div className="recommended-tile">
                  <div className="recommended-text">
                    <h2>LEARN</h2>
                    <p>The power of self-awareness</p>
                  </div>
                  <div className="recommended-button">
                    <Link className="btn btn-primary" to="https://www.strengthsprofile.com/en-gb/blog/post/194/the-power-of-self-awareness-how-identifying-your-strengths-and-weaknesses-can-he" target="_blank">
                      Learn more
                    </Link>
                  </div>
                </div>
              </Col>
              <Col xs={12} lg={4}>
                <div className="recommended-tile media">
                  <div className="recommended-text">
                    <img src={ted} alt="Recommended for you" />
                    <h2>Increase your self-awareness with one simple fix</h2>
                  </div>
                  <div className="recommended-button">
                    <Link
                      className="btn btn-primary"
                      to="https://www.youtube.com/watch?v=tGdsOXZpyWE&ab_channel=TEDxTalks"
                      target="_blank"
                    >
                      Watch
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
        </>
    )

};