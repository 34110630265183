import React, { useEffect, useState } from 'react';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { JumboTwoColsLeft } from '@capp.micro/shared/components';
import Layout from '../components/layout';
import Brand from '../Brand';
import SubHero from '../components/subHero';
import practice from '../images/practice.svg';
import { getApiUserMetadata } from '@capp.micro/shared/api';

export function Worksim() {
  const [userMetadata, setUserMetadata] = useState(null);
  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    getApiUserMetadata(user, getAccessTokenSilently, setUserMetadata);
  }, []);

  const userID = isAuthenticated ? user.sub : 'Unknown';
  const email = isAuthenticated ? encodeURIComponent(user.email) : '';
  const fname = isAuthenticated && userMetadata ? userMetadata.first_name : '';
  const lname = isAuthenticated && userMetadata ? userMetadata.last_name : '';

  return (
    <Layout>
      <SubHero
        welcomeTitle="Practice"
        title="Work Simulation"
        text=""
        image={practice}
        class="jumbo-section hero full-width hero-light capp-gradient sub-hero"
      />

      <JumboTwoColsLeft
        text="Success starts with true self-awareness.Explore your strengths, values, and preferences. Find your best career paths."
        buttonText="Start Now"
        link={
          Brand.ASSESSMENT_LINKS.WORK_SIM_LINK +
          '&cuid=' +
          userID +
          '&fname=' +
          fname +
          '&lname=' +
          lname +
          '&email=' +
          email +
          '&auid=104d7123-6216-4c60-8449-28bc31ff77ce'
        }
        class="mt-3 mb-10 brand-discover jumbo-section full-width"
      />
    </Layout>
  );
}

export default withAuthenticationRequired(Worksim, {
  loginOptions: {
    dbConnection: Brand.AUTH_CONNECTION,
    backgroundImage: Brand.SIGN_UP_BG,
    logo: Brand.SIGN_UP_LOGO,
    buttonColor: Brand.SIGN_UP_BUTTON_COLOR,
    buttonHoverColor: Brand.SIGN_UP_BUTTON_HOVER,
    buttonTextColor: Brand.BUTTON_TEXT_COLOR,
    headerColor: Brand.SIGN_UP_HEADER_COLOR,
    welcomeOneColor: Brand.WELCOME_TEXT_COLOR_ONE,
    welcomeTwoColor: Brand.WELCOME_TEXT_COLOR_TWO,
    welcomeTwo: Brand.WELCOME_TEXT,
    appName: Brand.LOG_IN_TITLE,
  },
});
