import React from 'react';
import { Link } from 'gatsby';
import {Col, Row} from 'react-bootstrap';
import PropTypes from 'prop-types';

export const PhaseCard = ({
    phase,
    children,
  }) => 
{  
  PhaseCard.propTypes = {
    phase: PropTypes.object,
    children: PropTypes.object
  }
  return (
    <div className="phase-card">
      <div
        className="phase-cover"
        style={{ backgroundImage: `url(${phase.image})` }}
      >
        <div className="phase-details">
          <div className="phase-days">
            <h2>Phase {phase.number}</h2>
            <h3>{phase.name}</h3>
          </div>
        </div>

          <div className="phase-hidden">
            <p>{phase.description}</p>
            {children}
          </div>
      </div>
    </div>
  );

}
export const LearningPathPhases = ({progressObject, phases}) => {
  LearningPathPhases.propTypes = {
    progressObject: PropTypes.object,
    phases: PropTypes.array
  }

    const unlock = (phase, id) => {
          return (
            <PhaseCard phase={phase} id={id} description={progressObject[phase.id] === 100 ? 'Completed' : ''}>
              
              <Link className="btn btn-primary" to={`/phases/${phase.id}`}>
                {progressObject[phase.id] === 100 ? 'Revisit' : 'Start'}
              </Link>
            </PhaseCard>
          );
      };

    return (
        <>
        <Row>
              <Col xs={12} style={{ marginBottom: '20px' }}>
                <h2>Your Learning Path</h2>
              </Col>
            </Row>
            <Row style={{ marginBottom: '40px' }}>
        {phases.map((phase) => (
            <Col xs={12} lg={4} key={phase.id}>
              {unlock(phase, phase.id)}
            </Col>
          ))}
          </Row>
          </>
        
    )
  };