import React from 'react';
import { SidebarHeader } from '@capp.micro/shared/components';
import { Helmet } from 'react-helmet';
import Layout from '../components/layout';
import Brand from '../Brand';
import { Container, Row, Col } from 'react-bootstrap';
import { withAuthenticationRequired } from '@auth0/auth0-react';

export function HintsAndTips(props) {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{Brand.PAGE6_TITLE}</title>
      </Helmet>

      <SidebarHeader
        class="sidebar-header page-two capp-gradient"
        heading="Take Action: Next Steps"
        subheading="British Airways"
      />

      <Container className="cards-wrapper">
        <Row>
          <Col xs={12} lg={9}>
            <p className="intro">
              This is the first year we’ll be completing this new Talent Cycle,
              but this process is something that will be happening at BA on an
              annual basis. Everyone will complete the online tool to receive a
              potential rating each year, just in the same way you receive a
              performance rating each year.
            </p>

            <p>
              But for now, until our next Talent Cycle, we suggest you take the
              below next steps:
            </p>

            <br />
            <h3>1. Continue your conversations</h3>

            <br />
            <p>
              At the heart of our BA Talent Cycle are the conversations you’ll
              have with your line managers about your strengths, development
              areas and motivation to grow here at BA. But these aren’t just for
              during the Talent Cycle, they’re for all year round. Make sure
              you’re having regular 1:1s with your line manager to keep the
              conversation alive.
            </p>

            <br />
            <h3>2. Create a development action plan</h3>

            <br />
            <p>
              Once you know your position on the Talent Grid, and you’ve had
              your ‘Growing your career’ conversation with your line manager,
              make a plan and set yourself achievable targets. Make the most of
              any development opportunities that you’re offered and check-in
              regularly on the progress you’re making.
            </p>
            <br />
            <h3>3. Explore the concepts shared</h3>

            <br />
            <p>
              If you are curious to find out more about some of the topics we
              covered in this toolkit, check out the further resources below.
            </p>
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={4}>
            <div class="sidebar-card quote journeys next-steps">
              <span class="quote-sign">READ - How to ask great questions</span>
              <p class="quote">
                is a short article providing additional insight into asking
                great questions at work.
                <br />
                <br />
                <a
                  href="https://hbr.org/2018/05/the-surprising-power-of-questions"
                  target="_blank"
                >
                  How to Ask Great Questions (hbr.org)
                </a>
              </p>
            </div>
          </Col>
          <Col xs={12} lg={4}>
            <div class="sidebar-card quote journeys next-steps">
              <span class="quote-sign">READ - The Person You Mean To Be</span>
              <p class="quote">
                is an evidence-based book which explores how to battle biases,
                champion inclusion and be an advocate.
                <br />
                <br />
                <a
                  href="https://fourminutebooks.com/the-person-you-mean-to-be-summary/#:~:text=The%20Person%20You%20Mean%20to%20Be%20will%20teach%20you%20all,have%20a%20right%20to%20claim."
                  target="_blank"
                >
                  The Person You Mean to Be Summary - Four Minute Books
                </a>
              </p>
            </div>
          </Col>
          <Col xs={12} lg={4}>
            <div class="sidebar-card quote journeys next-steps">
              <span class="quote-sign">LISTEN - How to Bust Bias at Work</span>
              <p class="quote">
                Psychologist Adam Grant interviews Tyece Wilkins, a diversity
                and inclusion senior advisor at BAE Systems. An evidence-based
                discussion about how workplaces can break barriers to help
                marginalized groups advance into leadership roles.
                <br />
                <br />
                <a
                  href="https://podcasts.apple.com/us/podcast/how-to-bust-bias-at-work/id1346314086?i=1000522109799"
                  target="_blank"
                >
                  WorkLife with Adam Grant: How to Bust Bias at Work on Apple
                  Podcasts
                </a>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}

export default withAuthenticationRequired(HintsAndTips, {
  loginOptions: {
    dbConnection: Brand.AUTH_CONNECTION,
    backgroundImage: Brand.SIGN_UP_BG,
    logo: Brand.SIGN_UP_LOGO,
    buttonColor: Brand.SIGN_UP_BUTTON_COLOR,
    buttonHoverColor: Brand.SIGN_UP_BUTTON_HOVER,
    buttonTextColor: Brand.BUTTON_TEXT_COLOR,
    headerColor: Brand.SIGN_UP_HEADER_COLOR,
    welcomeOneColor: Brand.WELCOME_TEXT_COLOR_ONE,
    welcomeTwoColor: Brand.WELCOME_TEXT_COLOR_TWO,
    welcomeTwo: Brand.WELCOME_TEXT,
    appName: Brand.LOG_IN_TITLE,
  },
});
