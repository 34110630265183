import { learnAboutYouRedirect, practiceQuestionsRedirect, immersiveAssessmentRedirect } from "./routes/redirect-routes";
import poweredLogo from './images/powered-by-capp.svg';

const TenantIdsPerEnvironment = {
  DEVELOPMENT: 'aeb7fcd9-ba59-4846-bd8a-721fbbd9816f',
  TEST: '5b6255cd-98ce-4b56-8c8e-3e3698ff15eb',
  LIVE: '7003bf8f-c928-4e32-a756-3257eb70f119',
};

const tenantIdToUse = (() => {
  if (
    TenantIdsPerEnvironment.DEVELOPMENT &&
    typeof window !== 'undefined' &&
    window.environment === 'dev'
  ) {
    return TenantIdsPerEnvironment.DEVELOPMENT;
  } else if (
    TenantIdsPerEnvironment.TEST &&
    typeof window !== 'undefined' &&
    window.environment === 'TEST'
  ) {
    return TenantIdsPerEnvironment.TEST;
  } else {
    return TenantIdsPerEnvironment.LIVE;
  }
})();

const routeMapping = {
  profileComplete: learnAboutYouRedirect,
  step1Complete: learnAboutYouRedirect,
  step2Complete: learnAboutYouRedirect,
  step3Complete: practiceQuestionsRedirect,
  step4Complete: practiceQuestionsRedirect,
  step5Complete: practiceQuestionsRedirect,
  step6Complete: practiceQuestionsRedirect,
  step7Complete: immersiveAssessmentRedirect
};
  
const AssessmentLinks = {
  DEVELOPMENT: null,
  TEST: null,
  LIVE: {
    PROFILE_LINK:
      'https://apitake.cappassessments.com/Assessments/PackagedAssessment/Register?k=_Tf3vkI1kkOcO8O0M3A9Qg',
    VALUES_LINK:
      'https://apitake.cappassessments.com/Assessments/PackagedAssessment/Register?k=1J_2qQfblUG8o6hrL8mgwg',
    PREF_LINK:
      'https://apitake.cappassessments.com/Assessments/PackagedAssessment/Register?k=xzotVhXZ-kCszkUw87eJdA',
    LEARNING_LINK:
      'https://apitake.cappassessments.com/Assessments/PackagedAssessment/Register?k=gqdyghmeAkaLYE5Tr6qWLg',
    WORK_SIM_LINK:
      'https://apitake.cappassessments.com/Assessments/PackagedAssessment/Register?k=3rAd3aDch0-gTKcNqCLv_A',
    COG_LINK:
      'https://apitake.cappassessments.com/Assessments/PackagedAssessment/Register?k=Vrt-A9ixwEe2vLe_MPf3QA',
    TRIAL_LINK:
      'https://apitake.cappassessments.com/Assessments/PackagedAssessment/Register?k=Th2lcGjuCUK1wFAsS06i1g',
    SHOWCASE_LINK:
      'https://apitake.cappassessments.com/Assessments/PackagedAssessment/Register?k=uZjDXKeLGkWFfhl_w6XUYA',
  },
};

const assessmentLinksToUse = (() => {
  if (AssessmentLinks.DEVELOPMENT && window.environment === 'DEV') {
    return AssessmentLinks.DEVELOPMENT;
  } else if (AssessmentLinks.TEST && window.environment === 'TEST') {
    return AssessmentLinks.TEST;
  } else {
    return AssessmentLinks.LIVE;
  }
})();

const Brand = {
  BRAND_NAME: 'Cappfinity',
  FLAG_NAME: 'Cappfinity',
  TENANT_ID: tenantIdToUse,
  ROUTE_MAPPING: routeMapping,
  ASSESSMENT_LINKS: assessmentLinksToUse,
  AUID: 'ab047230-207c-4d98-bdaf-3217898762ce',

  HOME_PAGE_TITLE: 'Cappfinity Preparation Plus',
  PAGE1_TITLE: 'Preparation hub - Cappfinity Preparation Plus',
  PAGE2_TITLE: 'Learn about you - Cappfinity Preparation Plus',
  PAGE3_TITLE: 'Practice zone - Cappfinity Preparation Plus',
  PAGE4_TITLE: 'Practice questions - Cappfinity Preparation Plus',
  PAGE5_TITLE: 'FAQs - Cappfinity Preparation Plus',
  PAGE6_TITLE: 'Hints and tips - Cappfinity Preparation Plus',

  SIGN_UP_BG: '',
  SIGN_UP_LOGO: '',
  SIGN_UP_BUTTON_COLOR: '',
  SIGN_UP_BUTTON_HOVER: '',
  BUTTON_TEXT_COLOR: '',
  SIGN_UP_HEADER_COLOR: '',
  WELCOME_TEXT: 'Welcome To Cappfinity',
  WELCOME_TEXT_COLOR_ONE: '',
  WELCOME_TEXT_COLOR_TWO: '',
  SIGN_UP_TITLE: 'Sign up to Preparation Plus',
  LOG_IN_TITLE: 'Log in to Preparation Plus',

  AUTH_CONNECTION: 'Cappfinity-PreparationPlus',
  POWERED_BY_CAPP_LOGO: poweredLogo,
  LOGO_ALT: 'Cappfinity Preparation Plus',
};

export default Brand;
